import { transformImageUrl } from '@kontent-ai/delivery-sdk'
import { useDevicePixelRatio } from '@vueuse/core'
import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
  const width = Number(modifiers?.width || 0)
  const height = Number(modifiers?.height || 0)

  if (src.includes('override=true')) {
    return { url: src }
  }

  const imageBuilder = transformImageUrl(src)
    .withAutomaticFormat()
    .withCompression('lossless')
    .withHeight(height)
    .withWidth(width)

  let transformedUrl = imageBuilder.getUrl()

  const { pixelRatio } = useDevicePixelRatio()
  transformedUrl += `&dpr=${pixelRatio.value}`

  return {
    url: transformedUrl
  }
}
